// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppWellTrackerInfo,
  AppWellsTreeMap,
  AppWellDetails,
  AppWellboreDataSelection,
} from "../components/_dashboard/app";

// ----------------------------------------------------------------------

export default function WellTracker() {
  return (
    <Page title="Well tracker | Alpha PGS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Track your wells</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <AppWellsTreeMap />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Container>
              <Grid item xs={12} md={12} lg={12} sx={{ mb: 5 }}>
                <AppWellDetails />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <AppWellboreDataSelection />
              </Grid>
            </Container>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <AppWellTrackerInfo />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
