// material
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Card,
  Typography,
  CardHeader,
  CardContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// utils
// ----------------------------------------------------------------------
function createData(channel) {
  return { channel };
}
const rows = [
  createData("ROP"),
  createData("WOP"),
  createData("Torque"),
  createData("RPM"),
  createData("Flow-In"),
];
export default function AppWellboreDataSelection() {
  return (
    <Card variant="outlined">
      <CardHeader title="Select channels " />
      <CardContent>
        <Typography variant="h6">Log data</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Channel</TableCell>
                <TableCell align="left">Streaming</TableCell>
                <TableCell align="left">History</TableCell>
                <TableCell align="left">Min value</TableCell>
                <TableCell align="left">Max value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.channel}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.channel}
                  </TableCell>
                  <TableCell align="left">
                    <Switch defaultChecked />
                  </TableCell>
                  <TableCell align="left">
                    <Switch defaultChecked />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      fullWidth
                      autoComplete="Minimum"
                      type="number"
                      label="Minimum"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      fullWidth
                      autoComplete="Maximum"
                      type="number"
                      label="Maximum"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
        <Typography variant="h6">Trajectory data</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Channel</TableCell>
                <TableCell align="left">Streaming</TableCell>
                <TableCell align="left">History</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key="Trajectory"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Trajectory
                </TableCell>
                <TableCell align="left">
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="left">
                  <Switch defaultChecked />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Go to monitoring board
        </LoadingButton>
      </CardContent>
    </Card>
  );
}
