// material
import { Card, Typography, CardHeader, CardContent } from "@mui/material";
// utils
// ----------------------------------------------------------------------

export default function AppWellDetails() {
  return (
    <Card variant="outlined">
      <CardHeader title="Well: TP-266  HGA-46" />
      <CardContent>
        <Typography variant="body1">
          <strong>Country:</strong> Algeria
        </Typography>
        <br></br>
        <Typography variant="body1">
          <strong>Site:</strong> Hassi Messaoud
        </Typography>
        <br></br>
        <Typography variant="body1">
          <strong>Start date:</strong> 16 Jun 1960
        </Typography>
        <br></br>
        <Typography variant="body1">
          <strong>Attribute 1:</strong> Value 1
        </Typography>
        <br></br>
        <Typography variant="body1">
          <strong>Attribute 2:</strong> Value 2
        </Typography>
        <br></br>
        <Typography variant="body1">
          <strong>Attribute 3:</strong> Value 3
        </Typography>
        <br></br>
        <Typography variant="body1">
          <strong>Attribute 4:</strong> Value 4
        </Typography>
      </CardContent>
    </Card>
  );
}
