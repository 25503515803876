import { Icon } from '@iconify/react';
import homeOutline from '@iconify/icons-eva/home-outline';
import trendingUp from '@iconify/icons-ic/trending-up';
import swapFill from '@iconify/icons-eva/swap-fill';
import radioButtonOnFill from '@iconify/icons-eva/radio-button-on-fill';
import radioButtonOffOutline from '@iconify/icons-eva/radio-button-off-outline';
import dropletOutline from '@iconify/icons-eva/droplet-outline';
import sheildOutline from '@iconify/icons-eva/shield-outline';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon(homeOutline)
  },
  {
    title: 'Wells tracker',
    path: '/dashboard/wells',
    icon: getIcon(trendingUp)
  },
  {
    title: 'Format converters',
    path: '/dashboard/converters',
    icon: getIcon(swapFill)
  },
  {
    title: 'Hole cleaning',
    path: '/dashboard/hole-cleaning',
    icon: getIcon(radioButtonOffOutline)
  },
  {
    title: 'Hole cementing',
    path: '/dashboard/hole-cementing',
    icon: getIcon(radioButtonOnFill)
  },
  {
    title: 'Hydrocarbon display',
    path: '/dashboard/hydrocarbon-display',
    icon: getIcon(dropletOutline)
  },
  {
    title: 'Overwatch agent',
    path: '/dashboard/overwatch-agent',
    icon: getIcon(sheildOutline)
  },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon(peopleFill)
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
